
import { useContext } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './pages/login'
import Usuarios from './pages/usuarios'
import Metricas from './pages/metricas'
import RutaPrivada from './componentes/rutaPrivada'
import ResultAlert from './componentes/resultAlert'
import { contextApp } from './context/appContext'
import Clientes from './pages/clientes'
const App = () => {
  const { openAlert, setOpenAlert, mensajeAlert } = useContext(contextApp)
  return (
    <>
      <ResultAlert open={openAlert} setOpen={setOpenAlert} mensajeAlert={mensajeAlert} />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />

          <Route path="/usuarios" element={
            <RutaPrivada>
              <Usuarios />
            </RutaPrivada>
          } />
          <Route path="/clientes" element={
            <RutaPrivada>
              <Clientes />
            </RutaPrivada>
          } />
          <Route path="/metricas" element={
            <RutaPrivada>
              <Metricas />
            </RutaPrivada>
          } />

        </Routes>
      </Router>
    </>
  )
}

export default App;



