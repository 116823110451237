import { useState, useEffect } from "react"
import { db } from "../../../firebase/firebaseConfig"
import { collection, onSnapshot, query } from "firebase/firestore"

const useGetClients = () => {
    const [clientes, Setclientes] = useState({})

    useEffect(() => {
        const consulta = query(
            collection(db, 'clientes')
        )
        const unsub = onSnapshot(consulta, (snapshot) => {
            const dataSnapShot = snapshot.docs.map((client) => {
                return {
                    idDoc: client.id,
                    name: client.data().name,
                    email: client.data().email,
                    phone: client.data().phone,
                    address: client.data().address,
                    adviser: client.data().adviser,
                    company: client.data().company,
                    profession: client.data().profession,
                    state: client.data().state,
                    firstContact: client.data().firstContact,
                    channelPreference: client.data().channelPreference,
                    createDate: client.data().createDate,
                    lastUpdate: client.data().lastUpdate,
                }
            })
            Setclientes(dataSnapShot)
        })
        return unsub

    }, [])
    return { clientes }
}

export default useGetClients