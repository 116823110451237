
import { useState, forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ResultAlert({open, setOpen, mensajeAlert}) {
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
      <Snackbar open={open} autoHideDuration={mensajeAlert.duration} onClose={handleClose}>
        <Alert onClose={handleClose} severity={mensajeAlert.severity} sx={{ width: '100%' }}>
        {mensajeAlert.message}
        </Alert>
      </Snackbar>
  );
}