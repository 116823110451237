import System from '../../componentes/sistema'
import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import {

    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title

} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { Pie } from 'react-chartjs-2'
import styled from 'styled-components'


ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title
)
export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
}

const data = {
    labels: ['piso 7', 'piso 9', 'piso 10', 'disponible'],
    datasets: [
        {
            label: '# of Votes',
            data: [12, 19, 3, 5],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1,
        },
    ],
}

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']

export const dataLine = {
    labels,
    datasets: [
        {
            label: 'Clintes Nuevos',
            data: [30, 50, 75, 90, 110, 120, 150],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Clientes renovados',
            data: [30, 50, 35, 90, 80, 120, 130],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
}



const data2 = {
    labels: ['disponible', 'ocupado',],
    datasets: [
        {
            label: '# of Votes',
            data: [25, 75],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',

            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
            ],
            borderWidth: 1,
        },
    ],
}



const Metricas = () => {

    return (
        <>
            <System>
                <Container>
                    <Div>
                        <Pie data={data} />
                        <h3>Ocupación Total por piso</h3>
                    </Div>
                    <Div>
                        <Pie data={data2} />
                        <h3>Ocupación Piso 9</h3>
                    </Div>
                </Container>
                <DivLine>
                    <Line options={options} data={dataLine} />
                    <h3>Ventas</h3>
                </DivLine>
            </System>
        </>
    )
}

export default Metricas

const Container = styled.div`
    width:70%;
    margin:auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    canvas{
        width: 300px !important;
        height: 300px !important;
    }

`

const Div = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
`

const DivLine = styled.div`
width: 80%;
margin:auto;
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
`