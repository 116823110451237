import { useContext } from "react"
import { auth } from "../../../firebase/firebaseConfig"
import { signInWithEmailAndPassword } from "firebase/auth"
import { useNavigate } from "react-router-dom"
import { contextApp } from "../../../context/appContext"


const useLogin = () => {
    const navigate = useNavigate()
    const { setOpenAlert, setMensajeAlert } = useContext(contextApp)

    const login = async ({ email, password }) => {
        try {
            await signInWithEmailAndPassword(auth, email, password)
            navigate('/usuarios')
        } catch (err) {
            let mensaje
            switch (err.code) {
                case "auth/weak-password":
                    mensaje = "La contraseña debe tener al menos seis caracteres."
                    break
                case "auth/wrong-password":
                    mensaje = "Contraseña Inválida"
                    break
                case "auth/user-not-found":
                    mensaje = "No existe Usuario"
                    break
                case "auth/invalid-email":
                    mensaje = "correo inválido"
                    break
                default:
                    mensaje = "Ocurrió un error"
                    break
            }
            setMensajeAlert({
                severity: 'error',
                message: mensaje,
                duration: 6000
            })
            setOpenAlert(true)
        }
    }
    return { login }
}

export default useLogin