import { useForm, Controller } from 'react-hook-form'
import LogoBao from '../../componentes/logoBao'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'
import { Stack } from '@mui/material'
import { Button } from '@mui/material'
import useLogin from './hooks/useLogin'

const Login = () => {
    const { control, handleSubmit } = useForm({ defaultValues: { email: '', password: '' } })
    const { login } = useLogin()
    // const inicioSesion = async (e) => {
    //     e.preventDefault();

    //     const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
    //     if (!expresionRegular.test(correo)) {
    //         setEstadoAlert(true);
    //         setMensajeAlert({
    //             mensajeAlert: 'Correo no válido',
    //             type: 'error'
    //         })
    //         return;
    //     }
    //     if (correo === '' || pass === '') {
    //         setEstadoAlert(true);
    //         setMensajeAlert({
    //             mensajeAlert: 'Por favor rellena todos los campos',
    //             type: 'error'
    //         })

    //         return;
    //     }

    //     try {
    //         await signInWithEmailAndPassword(auth, correo, pass);
    //         navigate('/usuarios');

    //     } catch (err) {
    //         setEstadoAlert(true);

    //         let mensaje;
    //         switch (err.code) {
    //             case "auth/weak-password":
    //                 mensaje = "La contraseña debe tener al menos seis caracteres."
    //                 break;
    //             case "auth/wrong-password":
    //                 mensaje = "Contraseña Inválida"
    //                 break;
    //             case "auth/user-not-found":
    //                 mensaje = "No existe Usuario"
    //                 break;
    //             case "auth/invalid-email":
    //                 mensaje = "correo inválido"
    //                 break;
    //             default:
    //                 mensaje = "Ocurrió un error"
    //                 break;
    //         }
    //         setMensajeAlert({
    //             mensajeAlert: mensaje,
    //             type: 'error'
    //         })
    //     }

    // }
    return (
        <Overlay>
            <main>
                <DivLogo>
                    <LogoBao />
                </DivLogo>
                <form onSubmit={handleSubmit(login)}>
                    <Stack spacing={2}>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => <TextField
                                label="Email"
                                {...field}
                            />}
                        />
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => <TextField

                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                {...field}

                            />}
                        />
                    </Stack>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Iniciar Sesión
                    </Button>
                </form>
            </main>
        </Overlay>
    )
}

export default Login

const Overlay = styled.div`
width: 100vw;
height: 100vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
main{
    width: 30%;

}
`

const DivLogo = styled.div`
    width: 200px;
    margin: auto;
`
