import { db } from "../../../firebase/firebaseConfig"
import { doc, updateDoc, addDoc, collection } from "firebase/firestore"
import { createUserWithEmailAndPassword } from "firebase/auth"
import { auth } from "../../../firebase/firebaseConfig"




export const createUser = async ({ nombreUsuario, rolUsuario, correo, estado, pass }) => {
  try {
    const infoUser = await createUserWithEmailAndPassword(auth, correo, pass)
    addDoc(collection(db, "users"), {
      idUsuario: infoUser.user.uid,
      nombreUsuario,
      rolUsuario,
      correo,
      estado,
      createDate: new Date(),
      lastUpdate: new Date()
    })
    return {
      status: 'ok'
    }
  } catch (err) {
    return {
      status: 'error',
      data: err
    }
  }

}




export const updateUser = async ({ idDoc, nombreUsuario, rolUsuario, estado }) => {
  const documento = doc(db, "users", idDoc)
  try {

    await updateDoc(documento, {
      nombreUsuario,
      rolUsuario,
      estado,
      lastUpdate: new Date()
    })

    return {
      status: 'ok',
    }
  } catch (err) {
    return {
      status: 'error',
      data: err
    }
  }
}