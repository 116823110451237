import { useState, useEffect } from "react"
import { db } from "../../../firebase/firebaseConfig"
import { collection, onSnapshot, query } from "firebase/firestore"

const useGetUsers = () => {
    const [usuarios, setUsuarios] = useState({})

    useEffect(() => {
        const consulta = query(
            collection(db, 'users')
        )
        const unsub = onSnapshot(consulta, (snapshot) => {
            const dataSnapShot = snapshot.docs.map((user) => {
                return {
                    idDoc: user.id,
                    idRelation: user.data().idUsuario,
                    name: user.data().nombreUsuario,
                    email: user.data().correo,
                    rol: user.data().rolUsuario,
                    userStatus: user.data().estado,
                    createDate: user.data().createDate,
                    lastUpdate: user.data().lastUpdate,
                }
            })
            setUsuarios(dataSnapShot)
        })
        return unsub

    }, [])
    return { usuarios }
}

export default useGetUsers