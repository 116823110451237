
import { useEffect, useState } from "react"
import { getClientByID } from "../helpers/firebase"


const useGetClientById = (id, reset) => {
    const [client, clientById] = useState([])

    useEffect(() => {
        const clientByID = async () => {
            const response = await getClientByID(id)
            if (response.status === 'ok') {
                reset({
                    name: response.data.name,
                    email: response.data.email,
                    phone: response.data.phone,
                    address: response.data.address,
                    adviser: response.data.adviser.id,
                    company: response.data.company,
                    profession: response.data.profession,
                    state: response.data.state.value,
                    firstContact: response.data.firstContact.value,
                    channelPreference: response.data.channelPreference.value,
                })
            } else {
                console.log(response.data)
            }
        }
        id && clientByID()
    }, [id, reset])

    return { client }
}

export default useGetClientById