import { db } from "../../../firebase/firebaseConfig"
import { collection, query, onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"

const useGetInfoNewUser = (openModal, reset) => {
    const [roles, setRoles] = useState([])
    const [userStates, setUserStates] = useState([])

    useEffect(() => {
        const infoNewUser = async () => {
            //consulta Roles
            const RolesQuery = query(collection(db, 'roles'))
            const unsubRoles = onSnapshot(RolesQuery, (snapshot) => {
                const dataSnapShot = snapshot.docs.map((rol) => {
                    return {
                        id: rol.id,
                        name: rol.data().name,
                        label: rol.data().label,
                    }
                })
                setRoles(dataSnapShot)
            })
            //consulta userStates
            const userStatesQuery = query(collection(db, 'userStates'))
            const unsubUserStates = onSnapshot(userStatesQuery, (snapshot) => {
                const dataSnapShot = snapshot.docs.map((state) => {
                    return {
                        id: state.id,
                        name: state.data().name,
                        label: state.data().label,
                    }
                })
                setUserStates(dataSnapShot)
            })

            reset({
                name: '',
                email: '',
                pass1: '',
                pass2: '',
                rol: 'admin',
                userState: 'active',
            })
            return () => {
                unsubRoles()
                unsubUserStates()
            }
        }

        openModal && infoNewUser()
    }, [openModal, reset])




    return { roles, userStates }
}

export default useGetInfoNewUser