
import { useContext } from "react"
import { updateClient } from "../helpers/firebase"
import { contextApp } from "../../../context/appContext"

const useUpdateClient = (onClose) => {
  const { setOpenAlert, setMensajeAlert, setLoadingData } = useContext(contextApp)

  const update = async (dataUpdate) => {

    setLoadingData(true)
    const response = await updateClient(dataUpdate)
    if (response.status === 'ok') {
      setMensajeAlert({
        severity: 'success',
        message: 'El cliente se editó correctamente',
        duration: 4000
      })
      onClose()
    } else {
      setMensajeAlert({
        severity: 'error',
        message: 'ocurió un error',
        duration: 4000
      })
    }
    setOpenAlert(true)
    setLoadingData(false)
  }

  return { update }
}

export default useUpdateClient