import { db } from "../../../firebase/firebaseConfig"
import { collection, query, onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"

const useGetInfoNewClient = (openModal, reset) => {
    const [channelPreference, setChannelPreference] = useState([])
    const [clientsStates, setClientsStates] = useState([])
    const [firstContacts, setFirstContacts] = useState([])
    const [advisors, setadvisors] = useState([])

    useEffect(() => {
        const infoNewUser = async () => {
            //consulta Roles
            const firstQuery = query(collection(db, 'firstContactClient'))
            const unsubFirstContact = onSnapshot(firstQuery, (snapshot) => {
                const dataSnapShot = snapshot.docs.map((first) => {
                    return {
                        id: first.id,
                        value: first.data().value,
                        label: first.data().label,
                    }
                })
                setFirstContacts(dataSnapShot)
            })
            //consulta userStates
            const userStatesQuery = query(collection(db, 'clientesStates'))
            const unsubUserStates = onSnapshot(userStatesQuery, (snapshot) => {
                const dataSnapShot = snapshot.docs.map((state) => {
                    return {
                        id: state.id,
                        value: state.data().value,
                        label: state.data().label,
                    }
                })
                setClientsStates(dataSnapShot)
            })
            //consulta canales de preferencia
            const ChannelsQuery = query(collection(db, 'channelPreferences'))
            const unsubChannelPreference = onSnapshot(ChannelsQuery, (snapshot) => {
                const dataSnapShot = snapshot.docs.map((ch) => {
                    return {
                        id: ch.id,
                        value: ch.data().value,
                        label: ch.data().label,
                    }
                })
                setChannelPreference(dataSnapShot)
            })
            //consulta asesor
            const advisorsQuery = query(collection(db, 'advisors'))
            const unsubAdvisors = onSnapshot(advisorsQuery, (snapshot) => {
                const dataSnapShot = snapshot.docs.map((ch) => {
                    return {
                        value: ch.id,
                        label: ch.data().name,

                    }
                })
                setadvisors(dataSnapShot)
            })

            reset({
                name: '',
                email: '',
                phone: '',
                address: '',
                adviser: '3VVzsU8baG8omZRjy6OD',
                company: '',
                profession: '',
                state: 'active',
                firstContact: 'ws',
                channelPreference: 'email',
            })
            return () => {
                unsubFirstContact()
                unsubUserStates()
                unsubChannelPreference()
                unsubAdvisors()
            }
        }

        openModal && infoNewUser()
    }, [openModal, reset])




    return { channelPreference, clientsStates, firstContacts, advisors, }
}

export default useGetInfoNewClient