import { useState, createContext } from "react"

const contextApp = createContext()

const ProviderApp = ({ children }) => {
  const [loadingData, setLoadingData] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [mensajeAlert, setMensajeAlert] = useState({
    severity: 'error',
    message: '',
    duration: 0
  })

  const handleClose = () => {
    setOpenModal(false)
  }

  return (
    <contextApp.Provider value={
      {
        openAlert,
        setOpenAlert,
        mensajeAlert,
        setMensajeAlert,
        loadingData,
        setLoadingData,
        openModal,
        setOpenModal,
        handleClose,//esto es necesario porque la Libreria MUI lo necesita asi para cerrar los modales (Dialog)
      }}
    >
      {children}
    </contextApp.Provider>
  )
}

export { contextApp, ProviderApp, }