
import { useContext } from 'react'
import { useForm, Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import { Button } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CircularProgress from '@mui/material/CircularProgress'
import { contextApp } from '../../../../context/appContext'
import useGetInfoNewUser from '../../hooks/useGetInfoNewUser'
import useCreateUser from '../../hooks/useCreateUser'

const ModalUserCreate = ({ openModal, onClose }) => {
    const { loadingData } = useContext(contextApp)
    const { control, handleSubmit, reset, formState: { errors }, getValues } = useForm()
    const { roles, userStates } = useGetInfoNewUser(openModal, reset)
    const { create } = useCreateUser(onClose)


    const newUser = (data) => {

        const objRol = roles.find(rol => rol.name === data.rol)
        const objState = userStates.find(state => state.name === data.userState)
        const dataCreate = {
            nombreUsuario: data.name,
            rolUsuario: {
                state: objRol.name,
                label: objRol.label,
            },
            correo: data.email,
            estado: {
                state: objState.name,
                label: objState.label,
            },
            pass: data.pass1
        }

        create(dataCreate)
    }

    //FormValidations
    const validateConfirmPassword = (pass2) => pass2 === getValues('pass1') || 'las contraseñas deben coincidir'
    const formRules = {
        name: { required: "Nombre es requerido" },
        email: { required: "Email es requerido" },
        pass1: { required: "Contraseña es requerida" },
        pass2: { required: "Repite la contraseña", validate: validateConfirmPassword },
    }

    return (
        <Dialog open={openModal} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Nuevo Usuario</DialogTitle>
            <form onSubmit={handleSubmit(newUser)}>
                <DialogContent>
                    <Stack spacing={2}>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue=''
                            rules={formRules.name}
                            render={({ field }) => <TextField
                                label="Nombre"
                                fullWidth
                                {...field}
                                helperText={errors.name ? errors.name.message : null}
                                error={Boolean(errors.name)}
                            />}
                        />
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=''
                            rules={formRules.email}
                            render={({ field }) => <TextField
                                label="Correo Electrónico"
                                type='email'
                                fullWidth
                                {...field}
                                helperText={errors.email ? errors.email.message : null}
                                error={Boolean(errors.email)}
                            />}
                        />
                        <Controller
                            name="pass1"
                            control={control}
                            defaultValue=''
                            rules={formRules.pass1}
                            render={({ field }) => <TextField
                                label="Contraseña"
                                fullWidth
                                {...field}
                                type='password'
                                helperText={errors.pass1 ? errors.pass1.message : null}
                                error={Boolean(errors.pass1)}
                            />}
                        />
                        <Controller
                            name="pass2"
                            control={control}
                            defaultValue=''
                            rules={formRules.pass2}
                            render={({ field }) => <TextField
                                label="Repite Contraseña"
                                fullWidth
                                {...field}
                                type='password'
                                helperText={errors.pass2 ? errors.pass2.message : null}
                                error={Boolean(errors.pass2)}
                            />}
                        />
                        <Controller
                            name="rol"
                            control={control}
                            defaultValue=''
                            render={({ field }) => <FormControl>
                                <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                                <Select
                                    labelId="rol"
                                    id="rol"
                                    defaultValue='admin'
                                    label="Rol"
                                    {...field}
                                >
                                    {
                                        roles.map((rol) =>
                                            <MenuItem value={rol.name} key={rol.id} >{rol.label}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>}
                        />
                        <Controller
                            name="userState"
                            control={control}
                            defaultValue=''
                            render={({ field }) => <FormControl>
                                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                                <Select
                                    labelId="userState"
                                    id="userState"
                                    value=''
                                    label="Estado"
                                    {...field}
                                >
                                    {
                                        userStates.map((state) =>
                                            <MenuItem value={state.name} key={state.id}>{state.label}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>}
                        />

                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button type='submit' variant="contained" color="success" disabled={loadingData}>
                        {loadingData && <CircularProgress size={20} sx={{ marginRight: '10px', color: '#FFF' }} />}
                        Crear
                    </Button>
                    <Button onClick={onClose} variant="contained" color="error" disabled={loadingData}>Cancelar</Button>
                </DialogActions>
            </form>
        </Dialog >
    )
}

export default ModalUserCreate