import { db } from "../../../firebase/firebaseConfig"
import { doc, updateDoc, addDoc, collection, getDoc } from "firebase/firestore"


export const getClientByID = async (id) => {
  try {

    const documento = await getDoc(doc(db, 'clientes', id))
    if (documento.exists) {
      return {
        status: 'ok',
        data: documento.data()
      }
    } else {
      throw new Error('no se encontró el archivo')
    }
  } catch (err) {
    return {
      status: 'error',
      data: err
    }
  }
}

export const createClient = async (
  {
    name,
    email,
    phone,
    address,
    adviser,
    company,
    profession,
    state,
    firstContact,
    channelPreference,
  }
) => {
  try {

    addDoc(collection(db, "clientes"), {
      name,
      email,
      phone,
      address,
      adviser,
      company,
      profession,
      state,
      firstContact,
      channelPreference,
      createDate: new Date(),
      lastUpdate: new Date()
    })
    return {
      status: 'ok'
    }
  } catch (err) {
    return {
      status: 'error',
      data: err
    }
  }

}


export const updateClient = async ({
  idDoc,
  name,
  phone,
  address,
  adviser,
  company,
  profession,
  state,
  firstContact,
  channelPreference,
}) => {
  const documento = doc(db, "clientes", idDoc)
  try {

    await updateDoc(documento, {
      name,
      phone,
      address,
      adviser,
      company,
      profession,
      state,
      firstContact,
      channelPreference,
      lastUpdate: new Date()
    })

    return {
      status: 'ok',
    }
  } catch (err) {
    return {
      status: 'error',
      data: err
    }
  }
}