import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import GlobalStyle from "./theme/GlobalStyle"
import { ProviderApp } from './context/appContext'


import { ProviderSession } from './context/contextSession'
const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <ProviderSession>
      <ProviderApp>
        <App />
      </ProviderApp>
    </ProviderSession>
  </React.StrictMode>
);



