import { useContext } from 'react'
import { useForm, Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import { Button } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CircularProgress from '@mui/material/CircularProgress'
import { contextApp } from '../../../../context/appContext'
import useGetInfoNewClient from '../../hooks/useGetInfoNewUser'
import useCreateClient from '../../hooks/useCreateClient'

const ModalUserCreate = ({ openModal, onClose }) => {
    const { loadingData } = useContext(contextApp)
    const { control, handleSubmit, reset, formState: { errors } } = useForm()
    const { channelPreference, clientsStates, advisors, firstContacts } = useGetInfoNewClient(openModal, reset)
    const { create } = useCreateClient(onClose)

    const newUser = (data) => {
        const objChannel = channelPreference.find(ch => ch.value === data.channelPreference)
        const objState = clientsStates.find(state => state.value === data.state)
        const objAdvisor = advisors.find(adv => adv.value === data.adviser)
        const objFisrt = firstContacts.find(first => first.value === data.firstContact)

        const dataCreate = {
            name: data.name,
            email: data.email,
            phone: data.phone,
            address: data.address,
            adviser: {
                id: objAdvisor.value,
            },
            company: data.company,
            profession: data.profession,
            state: {
                value: objState.value,
                label: objState.label
            },
            firstContact: {
                value: objFisrt.value,
                label: objFisrt.label
            },
            channelPreference: {
                value: objChannel.value,
                label: objChannel.label
            },
        }

        create(dataCreate)
    }

    //FormValidations

    const formRules = {
        name: { required: "Nombre es requerido" },
        email: { required: "Email es requerido" },
        pass1: { required: "Contraseña es requerida" },
        pass2: { required: "Repite la contraseña" },
    }

    return (
        <Dialog open={openModal} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Nuevo Cliente</DialogTitle>
            <form onSubmit={handleSubmit(newUser)}>
                <DialogContent>
                    <Stack spacing={2}>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue=''
                            rules={formRules.name}
                            render={({ field }) => <TextField
                                label="Nombre"
                                fullWidth
                                {...field}
                                helperText={errors.name ? errors.name.message : null}
                                error={Boolean(errors.name)}
                            />}
                        />
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=''
                            render={({ field }) => <TextField
                                label="Correo Electrónico"
                                type='email'
                                fullWidth
                                {...field}
                                helperText={errors.email ? errors.email.message : null}
                                error={Boolean(errors.email)}
                            />}
                        />
                        <Controller
                            name="phone"
                            control={control}
                            defaultValue=''
                            render={({ field }) => <TextField
                                label="Teléfono"
                                fullWidth
                                {...field}
                                helperText={errors.phone ? errors.phone.message : null}
                                error={Boolean(errors.phone)}
                            />}
                        />
                        <Controller
                            name="company"
                            control={control}
                            defaultValue=''
                            render={({ field }) => <TextField
                                label="Empresa"
                                fullWidth
                                {...field}
                                helperText={errors.company ? errors.company.message : null}
                                error={Boolean(errors.company)}
                            />}
                        />
                        <Controller
                            name="profesion"
                            control={control}
                            defaultValue=''
                            render={({ field }) => <TextField
                                label="Profesión"
                                fullWidth
                                {...field}
                                helperText={errors.profesion ? errors.profesion.message : null}
                                error={Boolean(errors.profesion)}
                            />}
                        />

                        <Controller
                            name="adviser"
                            control={control}
                            defaultValue=''
                            render={({ field }) => <FormControl>
                                <InputLabel id="demo-simple-select-label">Asesor</InputLabel>
                                <Select
                                    labelId="asesor"
                                    id="asesor"
                                    defaultValue='admin'
                                    label="Asesor"
                                    {...field}
                                >
                                    {
                                        advisors.map((adv) =>
                                            <MenuItem value={adv.value} key={adv.id} >{adv.label}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>}
                        />
                        <Controller
                            name="firstContact"
                            control={control}
                            defaultValue=''
                            render={({ field }) => <FormControl>
                                <InputLabel id="firstContact">Primer Contacto</InputLabel>
                                <Select
                                    labelId="firstContact"
                                    id="firstContact"
                                    defaultValue='admin'
                                    label="Primer Contacto"
                                    {...field}
                                >
                                    {
                                        firstContacts.map((first) =>
                                            <MenuItem value={first.value} key={first.id} >{first.label}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>}
                        />
                        <Controller
                            name="channelPreference"
                            control={control}
                            defaultValue=''
                            render={({ field }) => <FormControl>
                                <InputLabel id="channelPreference">Canal de Preferencia</InputLabel>
                                <Select
                                    labelId="channelPreference"
                                    id="channelPreference"
                                    defaultValue='admin'
                                    label="Canal de preferencia"
                                    {...field}
                                >
                                    {
                                        channelPreference.map((cha) =>
                                            <MenuItem value={cha.value} key={cha.id} >{cha.label}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>}
                        />
                        <Controller
                            name="state"
                            control={control}
                            defaultValue=''
                            render={({ field }) => <FormControl>
                                <InputLabel id="state">Estado</InputLabel>
                                <Select
                                    labelId="state"
                                    id="state"
                                    value=''
                                    label="Estado"
                                    {...field}
                                >
                                    {
                                        clientsStates.map((state) =>
                                            <MenuItem value={state.value} key={state.id}>{state.label}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>}
                        />

                    </Stack>

                </DialogContent>
                <DialogActions>
                    <Button type='submit' variant="contained" color="success" disabled={loadingData}>
                        {loadingData && <CircularProgress size={20} sx={{ marginRight: '10px', color: '#FFF' }} />}
                        Crear
                    </Button>
                    <Button onClick={onClose} variant="contained" color="error" disabled={loadingData}>Cancelar</Button>
                </DialogActions>
            </form>
        </Dialog >
    )
}

export default ModalUserCreate