import { db } from "../../../firebase/firebaseConfig"
import { collection, getDocs, query, where, onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"

const useUserById = (idRealation, openModal, reset) => {
    const [userById, setUserById] = useState([])
    const [roles, setRoles] = useState([])
    const [userStates, setUserStates] = useState([])

    useEffect(() => {

        const usuarioPorId = async () => {
            const userQuery = query(
                collection(db, 'users'),
                where('idUsuario', '==', idRealation)
            )
            const userSnapshot = await getDocs(userQuery)

            userSnapshot.forEach((doc) => {
                setUserById({
                    id: doc.id,
                    data: doc.data(),
                })
            })
            //consulta Roles
            const RolesQuery = query(collection(db, 'roles'))
            const unsubRoles = onSnapshot(RolesQuery, (snapshot) => {
                const dataSnapShot = snapshot.docs.map((rol) => {
                    return {
                        id: rol.id,
                        name: rol.data().name,
                        label: rol.data().label,
                    }
                })
                setRoles(dataSnapShot)
            })
            //consulta userStates
            const userStatesQuery = query(collection(db, 'userStates'))
            const unsubUserStates = onSnapshot(userStatesQuery, (snapshot) => {
                const dataSnapShot = snapshot.docs.map((state) => {
                    return {
                        id: state.id,
                        name: state.data().name,
                        label: state.data().label,
                    }
                })
                setUserStates(dataSnapShot)
            })
            return () => {
                unsubRoles()
                unsubUserStates()
            }
        }

        idRealation && openModal && usuarioPorId()
    }, [idRealation, openModal])



    useEffect(() => {
        const resetForm = () => {
            reset({
                name: userById.data.nombreUsuario,
                rol: userById.data.rolUsuario.rol,
                userState: userById.data.estado.state
            })
        }
        userById.data && resetForm()
    }, [userById, reset])
    return { userById, roles, userStates }
}

export default useUserById