import { useContext } from "react"
import { contextApp } from "../../../context/appContext"
import { createClient } from "../helpers/firebase"



const useCreateClient = (onClose) => {
  const { setOpenAlert, setMensajeAlert, setLoadingData } = useContext(contextApp)

  const create = async (dataCreate) => {
    setLoadingData(true)
    const response = await createClient(dataCreate)
    if (response.status === 'ok') {
      setMensajeAlert({
        severity: 'success',
        message: 'el Cliente se creó correctamente',
        duration: 4000
      })
      onClose()
    } else {
      setMensajeAlert({
        severity: 'error',
        message: 'ocurió un error',
        duration: 4000
      })
    }
    setOpenAlert(true)
    setLoadingData(false)
  }

  return { create }

}

export default useCreateClient