import System from '../../componentes/sistema'
import { useState } from 'react'
import useGetClients from './hooks/useGetClients'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Fab, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import ModalClientsUpdate from './components/modalClientsUpdate'

import ModalUserCreate from './components/modalClientsCreate'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#b80b1d',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
}

const Clientes = () => {
    const { clientes } = useGetClients()
    const [OpenModalCreate, setOpenModalCreate] = useState(false)
    const [OpenModalEdit, setOpenModalEdit] = useState(false)
    const [idDoc, setidDoc] = useState('')
    const [idRealation, setIdRelation] = useState('')

    const viewModalEditarUsuario = ({ idDoc, idRealtion }) => {
        setidDoc(idDoc)
        setIdRelation(idRealtion)
        setOpenModalEdit(true)
    }
    const handleCloseCreate = () => setOpenModalCreate(false)
    const handleCloseEdit = () => setOpenModalEdit(false)

    return (
        <>
            <ModalClientsUpdate openModal={OpenModalEdit} onClose={handleCloseEdit} idDoc={idDoc} idRealation={idRealation} />
            <ModalUserCreate openModal={OpenModalCreate} onClose={handleCloseCreate} />
            <Fab sx={fabStyle} aria-label="add" color='secondary' onClick={() => { setOpenModalCreate(true) }}>
                <AddIcon />
            </Fab>
            <System>
                {clientes.length > 0 &&
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Nombre</StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                    <StyledTableCell>Teléfono</StyledTableCell>
                                    <StyledTableCell>Canal de contacto</StyledTableCell>
                                    <StyledTableCell>Estado</StyledTableCell>
                                    <StyledTableCell>Acciones</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clientes.map((client) => (
                                    <StyledTableRow key={client.idDoc}>
                                        <StyledTableCell component="th" scope="row">
                                            {client.name}
                                        </StyledTableCell>
                                        <StyledTableCell>{client.email}</StyledTableCell>
                                        <StyledTableCell>{client.phone}</StyledTableCell>
                                        <StyledTableCell>{client.channelPreference.label}</StyledTableCell>
                                        <StyledTableCell>{client.state.label}</StyledTableCell>
                                        <StyledTableCell>
                                            <IconButton aria-label="action" onClick={() => { viewModalEditarUsuario({ idDoc: client.idDoc }) }}>
                                                <EditIcon />
                                            </IconButton>
                                        </StyledTableCell>

                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}

            </System>
        </>
    )
}

export default Clientes