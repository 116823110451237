
import Header from "../header"
import MenuPrincipal from "../menuPrincipal"
import styled from 'styled-components'


const System = ({ children }) => {
    return (
        <>
            <Header></Header>
            <MenuPrincipal></MenuPrincipal>
            <PageDiv>
                {children}
            </PageDiv>
        </>

    )
}

export default System

const PageDiv = styled.div`
    width: 80%;
    float:right;
    background: #fff;
    padding: 2em;
    box-sizing: border-box;
    border-radius: 1em;
    margin-right: 10px;

`
